import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Appointment} from '../shared/appointment'

@Injectable({
  providedIn: 'root'
})

export class AppointmentService {
  apiURL = environment.apiBBDDURL;
  apiHubURL = environment.apiHub.URL;
  public firstPage: string = "";
  public prevPage: string = "";
  public nextPage: string = "";
  public lastPage: string = "";

  constructor(private httpClient: HttpClient) { }

  private headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });


  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  httpApiHubOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Basic ' + environment.apiHub.apiKey,
      'Content-Type': 'application/json'
    })
  }

  httpApiHubOptionsURLEncoded = {
    headers: new HttpHeaders({
      'Authorization': 'Basic ' + environment.apiHub.apiKey,
      'Content-Type': 'application/x-www-form-urlencoded' 
    })
  }

  //: Observable<any[]>(branchId: number)
  public getAppointments(branchId: string, callToClient : string, date : string, limitRecord? : string, offset?: string) : Observable<any>{

    let body = new URLSearchParams();
    body.set('id', branchId);
    body.set('callToClient', callToClient);
    body.set('date', date);
    if(limitRecord && offset){
      body.set('limitRecord', limitRecord);
      body.set('offset', offset);
    }
    
    return this.httpClient.post(this.apiHubURL + 'chatbot/getappointments', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getContacts(branchId: string, limitRecord? : string, offset?: string) : Observable<any>{
    
    let body = new URLSearchParams();
    body.set('id', branchId);
    if(limitRecord && offset){
      body.set('limitRecord', limitRecord);
      body.set('offset', offset);
    }
    

    return this.httpClient.post(this.apiHubURL + 'contact/getList', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public uploadFile(formData : any, branchId: string) : Observable<any>{
console.log('formDATa en el servicio', formData);
    let body = new URLSearchParams();
    body.set('id', branchId);
   
    return this.httpClient.post(this.apiURL + 'upload', formData);
  }

  public getContactPatients(contactId: string, limitRecord? : string, offset?: string) : Observable<any>{
    
    let body = new URLSearchParams();
    body.set('contactid', contactId);
    if(limitRecord && offset){
      body.set('limitRecord', limitRecord);
      body.set('offset', offset);
    }
    

    return this.httpClient.post(this.apiHubURL + 'childpatient/getcontactpatients', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getContactDetails(email : string, phone : string, branchId: string) : Observable<any>{
    let body = new URLSearchParams();
    body.set('email', email);
    body.set('phone', phone);
    body.set('branchId', branchId);

    return this.httpClient.post(this.apiHubURL + 'contact/getContact', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getContactPatientDetails(patientId) : Observable<any>{
    let body = new URLSearchParams();
    body.set('patientId', patientId);

    return this.httpClient.post(this.apiHubURL + 'childpatient/getcontactpatient', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public createContact(objectContact) : Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'contact/add',objectContact, this.httpApiHubOptions);
  }
  
  public createContactPatient(objectContact) : Observable<any> {
    return this.httpClient.post(this.apiHubURL + 'childpatient/newcontactpatient',objectContact, this.httpApiHubOptions);
  }

  public updateContact(objectContact) : Observable<any> {
    let body = new URLSearchParams();
    body.set('id', objectContact.id);
    body.set('name', objectContact.name);
    body.set('lastName', objectContact.lastname);
    body.set('email', objectContact.email);
    body.set('phone', objectContact.phone);
    body.set('homeAddress', objectContact.homeAddress);
    body.set('branchId', objectContact.branchId);
    body.set('id_optin_status', objectContact.id_optin_status);

    return this.httpClient.post(this.apiHubURL + 'contact/updateByid', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public updateContactPatient(objectContact) : Observable<any> {
    let body = new URLSearchParams();
    body.set('id', objectContact.id);
    body.set('name', objectContact.name);
    body.set('lastname', objectContact.lastname);
    body.set('birthdate', objectContact.birthdate);
    body.set('branchId', objectContact.branchId);

    return this.httpClient.post(this.apiHubURL + 'childpatient/updatecontactpatient', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public deleteContact(idContact) : Observable<any> {
    let body = new URLSearchParams();
    body.set('idContact', idContact);

    return this.httpClient.post(this.apiHubURL + 'contact/deletecontact', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public deleteContactPatient(idContact) : Observable<any> {
    let body = new URLSearchParams();
    body.set('idContact', idContact);

    return this.httpClient.post(this.apiHubURL + 'childpatient/deletecontactpatient', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getBranches(username) : Observable<any>{
    return this.httpClient.post(this.apiHubURL + 'dashboard/getuserbranchoffice', {username}, this.httpApiHubOptions);
  }

  public getAppointmentDetails(idAppo: string) : Observable<any>{

    let body = new URLSearchParams();
    body.set('id', idAppo);

    return this.httpClient.post(this.apiHubURL + 'chatbot/getappointments_details', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getSessionsByChannel(channel: string, date: string, branchId : string) : Observable<any>{

    let body = new URLSearchParams();
    body.set('channel',channel);
    body.set('date',date);
    body.set('branchid',branchId);

    return this.httpClient.post(this.apiURL + 'sessionsByChannel', body.toString(), this.httpOptions);
  }

  public getSessionsWebOff(channel: string, date: string, branchId : string, phone : string) : Observable<any>{

    let body = new URLSearchParams();
    body.set('channel',channel);
    body.set('date',date);
    body.set('branchid',branchId);
    body.set('phone',phone);

    return this.httpClient.post(this.apiHubURL + 'chatbot/sessions_web_off', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getSessionsByChannelOffset(date: string, limitRecord : string, offset: string, branchId : string, channel? : string) : Observable<any>{

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('date',date);
    body.set('limitRecord', limitRecord);
    body.set('offset', offset);
    body.set('branchid', branchId);
  
    if(channel){
      body.set('channel', channel);
    }

    return this.httpClient.post(this.apiHubURL + 'chatbot/sessionsByChannelOffset', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public updateViewStatus(tokenId: string, branchOfficeId : string) : Observable<any>{

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('tokenid',tokenId);
    
    return this.httpClient.post(this.apiHubURL + 'chatbot/updateView', body.toString(), this.httpApiHubOptionsURLEncoded);
  }

  public getNotifications(channel: string) : Observable<any>{

    let body = new URLSearchParams();

    return this.httpClient.post(this.apiHubURL + 'dashboard/getNotifications', {}, this.httpApiHubOptionsURLEncoded);
  }

  public getInteractionByToken(tokenId: string) : Observable<any>{

    let body = new URLSearchParams();
    body.set('id', tokenId);

    return this.httpClient.post(this.apiHubURL + 'chatbot/getwebinteractionsbytoken', body.toString(), this.httpApiHubOptionsURLEncoded);
  }
  
  // Error handling 
  // public errHandl(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   console.error(errorMessage);
  //   return throwError(errorMessage);
  // }
}