import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'list-call',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'full-calendar',
        loadChildren: () => import('./extension/full-event-calendar/full-event-calendar.module')
          .then(module => module.FullEventCalendarModule)
      },
      {
        path: 'calendar-events',
        loadChildren: () => import('./extension/calendar-events/calendar-events.module')
          .then(module => module.CalendarEventsModule)
      },
      {
        path: 'list-call',
        loadChildren: () => import('./pages/tables/call/call.module')
          .then(module => module.CallModule)
      },
      {
        path: 'profiles/profile',
        loadChildren: () => import('./pages/tables/profiles/profiles.module')
          .then(module => module.ProfilesModule)
      },
      {
        path: 'messages/mo',
        loadChildren: () => import('./pages/tables/sms-mo/sms-mo.module')
          .then(module => module.SmsMOModule)
      },
      {
        path: 'profiles/sms',
        loadChildren: () => import('./pages/tables/sms/sms.module')
          .then(module => module.SmsModule)
      },
      {
        path: 'profiles/facebook',
        loadChildren: () => import('./pages/tables/facebook/facebook.module')
          .then(module => module.FacebookModule)
      },
      {
        path: 'profiles/web',
        loadChildren: () => import('./pages/tables/web/web.module')
          .then(module => module.WebModule)
      },
      {
        path: 'profiles/webOff',
        loadChildren: () => import('./pages/tables/weboff/web-off.module')
          .then(module => module.WebOffModule)
      },
      {
        path: 'profiles/webOff/:uphone',
        loadChildren: () => import('./pages/tables/weboff/web-off.module')
          .then(module => module.WebOffModule)
      },
      {
        path: 'patient-details/:id/:pI',
        loadChildren: () => import("./pages/tables/patient-details/patient-details.module")
          .then(m => m.PatientDetailsModule)
      },
      {
        path: 'patient-record/:id/:pI',
        loadChildren: () => import("./pages/tables/patient-record/patient-record.module")
          .then(m => m.PatientRecordModule)
      },
      {
        path: 'patient-sms/:id/:pI',
        loadChildren: () => import("./pages/tables/patient-sms/patient-sms.module")
          .then(m => m.PatientSmsModule)
      },
      {
        path: 'contact-list',
        loadChildren: () => import("./pages/tables/contact/list-contact/contact.module")
          .then(m => m.ContactModule)
      },
      {
        path: 'add-contact',
        loadChildren: () => import("./pages/tables/contact/add-contact/add-contact.module")
          .then(m => m.AddContactModule)
      },
      {
        path: 'edit-contact',
        loadChildren: () => import("./pages/tables/contact/edit-contact/edit-contact.module")
          .then(m => m.EditContactModule)
      },
      {
        path: 'contact-patient/:id',
        loadChildren: () => import("./pages/tables/contact-patient/list/contact-patient.module")
          .then(m => m.ContactPatientModule)
      },
      {
        path: 'contact-patient-edit/:idc/:id',
        loadChildren: () => import("./pages/tables/contact-patient/edit-contact/edit-contact.module")
          .then(m => m.EditContactModule)
      },
      {
        path: 'add-contact-patient/:id',
        loadChildren: () => import("./pages/tables/contact-patient/add-contact/add-contact.module")
          .then(m => m.AddContactModule)
      },
      {
        path: 'History-campaing',
        loadChildren: () => import("./campaings/list/campaing-list.module")
          .then(m => m.CampaingListModule)
      },
      {
        path: 'Broadcast/AddBroadcast/InstantShipping',
        loadChildren: () => import("./campaings/instant-shipping/instant-shipping.module")
          .then(m => m.InstantShippingModule)
      },
      {
        path: 'Broadcast/Instantlist/:id',
        loadChildren: () => import("./campaings/list-instant/list-instant.module")
          .then(m => m.ListInstantModule)
      },
      {
        path: 'Settings/Variables',
        loadChildren: () => import("./campaings/variables/list/variable.module")
          .then(m => m.VariableModule)
      },
      {
        path: 'Settings/Variables/add',
        loadChildren: () => import("./campaings/variables/add-variable/add-variable.module")
          .then(m => m.AddVariableModule)
      },
      {
        path: 'Settings/Variables/edit/:idvariable',
        loadChildren: () => import("./campaings/variables/edit-variable/edit-variable.module")
          .then(m => m.EditVariableModule)
      },
      {
        path: 'Settings/Followup',
        loadChildren: () => import("./settings/followup/followup.module")
          .then(m => m.FollowupModule)
      },
      {
        path: 'Broadcast/CsvShipping',
        loadChildren: () => import("./campaings/csv-shipping/csv-shipping.module")
          .then(m => m.CsvShippingModule)
      },
      {
        path: 'Broadcast/Reminder',
        loadChildren: () => import("./campaings/reminder/reminder.module")
          .then(m => m.ReminderModule)
      },
      {
        path: 'smschat',
        loadChildren: () => import("./campaings/chatsms/chatsms.module")
          .then(m => m.ChatsmsModule)
      },
      {
        path: 'smschat/:uphone',
        loadChildren: () => import("./campaings/chatsms/chatsms.module")
          .then(m => m.ChatsmsModule)
      },
    ]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/authentication/auth-signin/auth-signin.module').then(module => module.AuthSigninModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
