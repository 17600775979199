import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from '../../../../../services/firebase.service';
import { ShareMessagesService } from '../../../../../services/share-messages.service';
import { ShareVariableService } from '../../../../../services/share-variable.service';
import { AppointmentService } from '../../../../../services/appointment.service';
import { Observable } from 'rxjs';

import { AuthService } from '../../../../../services/auth.service';
import { Router } from '@angular/router';
import { debug } from 'util';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit, OnDestroy {
  appointment$: Observable<any[]>;
  cantNoti;
  innerNotifications;
  notiHtmlContainer = document.querySelector('.noti-body');
  branches : any[] = []; 

  /*** Logout y Login */
  user = {
    email: '',
    password: ''
  }

  constructor(private fireService: FirebaseService, private authService: AuthService
    , private router: Router, private shareMessagesService: ShareMessagesService, private appointmentService: AppointmentService, private sharevariable : ShareVariableService) {

  }

  toggleWebChat() {
    this.shareMessagesService.toggleWebChat();
  }

  /** Metodos de login y logout para autetnticacion */

  // signup() {
  //   this.authService.signup(this.user.email, this.user.password)
  //     .then(response => {
  //       console.log('Respuesta de metodo signup');
  //     }
  //     ).catch(error => {
  //       console.error('Error in signUp method', error);
  //     })
  //   this.user.email = this.user.password = '';
  // }

  // signInWithGoogle() {
  //   this.authService.signInWithGoogle().then(resp => {
  //     this.router.navigate(['/list-call']);
  //   })
  //     .catch(error => {
  //       console.error('Error login con google ', error.message);
  //     })
  // }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
    // this.authService.logout()
    //   .then(res => this.router.navigate(['/auth']))
    //   .catch(error => {
    //     console.log('Error en logout', error.message)
    //   })
  }

  /** Fin metodos de autenticacion */
  private once(fn, context) {
    var result;

    return function () {
      if (fn) {
        result = fn.apply(context || this, arguments);
        fn = null;
      }

      return result;
    };
  }

  public getPollingNotifications() {
    this.appointmentService.getNotifications('')
      .subscribe(response => {
        if ((response != undefined) && !response.data) {
          return false;
        }
        let html = '';
        response.data.forEach(respo => {
          document.querySelector(".notification")
          console.log(respo);
          let elHtml = `
            <li class="notification">
              <div class="media">
                <div class="media-body">
                  <p><strong>User: ${respo.nameVisiting}</strong> <a class="btn btn-primary btn-sm float-right" href="/patient-details/${respo.id}/${respo.tokenid}" role="button"><i class="fas fa-eye"></i></a></p>
                  <p>Phone: ${respo.phone}</p>
                </div>
              </div>
            </li> 
    `;
          html += elHtml;
        })
        this.cantNoti = response.data.length;
        this.innerNotifications = html;
      })
  }

  public listenForRealTimeViewsChange() {
    // console.log('Entro a buscar elementos con el Observador');
    this.appointment$ = this.fireService.getObservableNotViewDoc$();
    // console.log('Entro a buscar elementos con el Observador2');
    this.appointment$.subscribe(response => {
      // console.log('Entro a buscar elementos con el Observador3');

      console.log("Mis elementos", response);
      if (response.length) {//Si no hay elementos que digan ViewStatus true, no se actua
        /**
         * Made a filter where we only look for an "added" type
         * type : added is throuhg when 
         * A page is load/refresh
         * A particular item was change from false to true in boolean field << weird, need more tests
         * A new element is add for its first time
        */
        // let elementsArr = response.filter(elem => elem.type === "modified");
        //if we had this type of event, we could look for now new modified call clients
        // if (!elementsArr.length) {
        let html = '';
        response.forEach(respo => {
          document.querySelector(".notification")
          console.log(respo);
          let elHtml = `
            <li class="notification">
              <div class="media">
                <div class="media-body">
                  <p><strong>User: ${respo.data.nameVisiting}</strong> <a class="btn btn-primary btn-sm float-right" href="/patient-details/${respo.id}/0" role="button"><i class="fas fa-eye"></i></a></p>
                  <p>Phone: ${respo.data.phone}</p>
                </div>
              </div>
            </li> 
    `;
          html += elHtml;
        })
        this.cantNoti = response.length;
        this.innerNotifications = html;
        setTimeout(() => {//Esperamos dos minutos (120000) para actuar sobre los elementos de las notificaciones
          console.log("Me ejecuto a los 4 seg");
        }, 4000);
        // }
      }
    });
  }

  ngOnInit() {
    // this.authService.authState.subscribe(state => {
    //   console.log('Status authService in nav-right.component.ts ', state.toString())
    // })
    this.getPollingNotifications();
     let user = JSON.parse(localStorage.getItem('currentUser'));
        this.appointmentService.getBranches(user.username).subscribe(branches => {
          if (branches && branches.data.length) {
            //@to-do Implementar idtokenclient para llamadas al webpoint.
            branches.data.forEach(branch =>{
              this.branches.push({
                  "id" : branch.branch_id,
                  "name" : branch.name,
                  "idtokenclient" : branch.idtokenclient
                })
            })
          }
        });
 

    const reloadInterval = 60;

    // timer(0, reloadInterval).pipe(
    //   mergeMap(_ => this.getPollingNotifications())
    // ).subscribe()

    setInterval(() => {
      // this.getPollingNotifications();
    }, 20 * 1000);
    // this.listenForRealTimeViewsChange();
  }

  changeBranch(idBranch){
    localStorage.setItem('currentBranchId',idBranch);
    this.sharevariable.changeIdBranch(idBranch);
    console.log('id branch', idBranch);
    location.reload();
  }

  ngOnDestroy() {
    /**TODO Desuscribir de Observable this.appointment$ */

  }
}
