import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {

  constructor(private af: AngularFirestore) { }

  //get patient by id
  public async getPatient(docId: string) {
    let currentPost: any;
    await new Promise((resolve) => {
      this.af.collection("appointments").doc(docId).valueChanges().pipe(
        take(1),
        map(post => {
          console.log(post);
          currentPost = post;
          return currentPost;
        })
      ).subscribe(() => {
        resolve();
      });
    }).catch(err => {
      console.log(err);
    })
    return currentPost;
  }

  public upPatientAsView(patientId : string){
    return this.af.collection('appointments').doc(patientId)
    .update({
      viewStatus : false
    });
  }

  public getObservableNotViewDoc$() {
    return this.af.collection('appointments', ref => ref
      .orderBy('startDateStatus', 'desc')
      .where('viewStatus', '==', true)
    )
      .snapshotChanges()
      .pipe(
        // take(1),
        map(changes => {
          let _changesarr = [];
          changes.forEach(t => {
            _changesarr.push({ "type": t.type, "id": t.payload.doc.id, "data": t.payload.doc.data() });
          })
          return _changesarr;
        })
      )
  }

  public async getNotViewDoc() {
    let viewDocs = this.af.collection('appointments', ref => ref
      .orderBy('startDateStatus', 'desc')
      .where('viewStatus', '==', true)
    ).get()
      .subscribe(response => {
        console.log();
      })

  }

  public async getPatient2(docId: string, dtExportButtonOptions: any) {
    let currentPost: any;
    await new Promise((resolve) => {
      this.af.collection("appointments").doc(docId).valueChanges().pipe(
        take(1),
        map(post => {
          currentPost = post;
          return currentPost;
        })
      ).subscribe(() => {
        resolve();
        dtExportButtonOptions.dataSource
      });
    }).catch(err => {
      console.log(err);
    })
  }

  public async getAllAppoinments() {
    let appon: any[] = [];
    let x: any;

    await new Promise((resolve) => {
      this.af.collection("appointments").snapshotChanges().pipe(
        take(1),
        map(changes => {
          let _changesarr = { "data": [] };
          changes.forEach(t => {
            _changesarr.data.push({ "id": t.payload.doc.id, "data": t.payload.doc.data() });
          })
          return _changesarr;
        })
      ).subscribe(_changesarr => {
        resolve();
        x = _changesarr;
      });
    })
    return x;
  }

  public getAppointments_false() {
    let appointments = this.af.collection("MoS").snapshotChanges();
    return appointments.pipe(
      map(appointments => {
        debugger;
        let _appoinments = [];
        appointments.forEach(p => {
          _appoinments.push(p);
        });
        return _appoinments;
      })
    )
  }

  public getAppointments() {
    let appointments = this.af.collection("MoS").snapshotChanges();
    return appointments.pipe(
      map(appointments => {
        let _appoinments = [];
        appointments.forEach(p => {
          _appoinments.push({
            "id": p.payload.doc.id,
            "data": p.payload.doc.data()
          });
        });
        return _appoinments;
      })
    )
  }
}
