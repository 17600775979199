// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCk3pv05LkOPIdDYIK2eC4y4HWCjF1uujw",
    authDomain: "lucybot-development-greusc.firebaseapp.com",
    databaseURL: "https://lucybot-development-greusc.firebaseio.com",
    projectId: "lucybot-development-greusc",
    storageBucket: "lucybot-development-greusc.appspot.com",
    messagingSenderId: "114847890960",
    appId: "1:114847890960:web:c3849825c280038000d495"

  },
  firebaseConfig2: {
    apiKey: "AIzaSyCPxopJzJq_JVKUBhLPd-mKZkbB2poNBEg",
    authDomain: "testing-purpose-99df5.firebaseapp.com",
    databaseURL: "https://testing-purpose-99df5.firebaseio.com",
    projectId: "testing-purpose-99df5",
    storageBucket: "",
    messagingSenderId: "75999084531",
    appId: "1:75999084531:web:9aead898df06d320b0dc86"
  },
  appBehaviour :{
    daysBefore : 40,
    recordsLimit : 5
  },
  interactiveChat :{
    appointment : {
      mtype: '1'
    },
    businness : {
      mtype: '2'
    }
  },
  apiHub :{
    apiKey : 'd2lua3lwczpOSzIyVUFucHVKTktLMWZzNE1mUFprVndiNlZXVmNCNw==',
    URL : 'https://api.dentalrobot.co/v1/'
  },
  reminder :{
    requiredFields : ['name', 'email', 'telephone', 'renewal_date']
  },
  
  // apiBBDDURL: 'https://apibotdevelopment.nixps.net/v1/123456/'
  apiBBDDURL: 'http://apibot.local/v1/123456/',
  // apiBBDDURL: 'https://apibot.nixps.net/v1/123456/'
    // authDomain: "demopetitsmiles-xgewvr.firebaseapp.com",
    // databaseURL: "https://demopetitsmiles-xgewvr.firebaseio.com",
    // projectId: "demopetitsmiles-xgewvr",
    // storageBucket: "demopetitsmiles-xgewvr.appspot.com",
    // messagingSenderId: "541780630509",
    // appId: "1:541780630509:web:fc5b772881b7c62d9ca5e4"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
