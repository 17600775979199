import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { CanLoad, CanActivate, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
status : string;
  constructor(private router: Router, private authService : AuthService ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
        // check if route is restricted by role
        if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
        }

        // authorised so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url }});
    return false;
}

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> {
  //   console.log('AuthGuard#canActivate called');

  //   let url: string = state.url;

  //   return this.authService.user.pipe(
  //   take(1),
  //   map(user => !!user),
  //   tap(loggedIn => {
  //     if (!loggedIn) {
  //       console.log('access denied')
  //       this.router.navigate(['/auth']);
  //     }
  //   })
  //   )    
  // }

//   async checkLogin(url: string) {
//     console.log('checklogin is called in auth guard');
    
//     let result = await this.authService.checkingPermissions();
//     if (this.authService.isLoggedIn) { return true; }
// console.log('Si estoy aqui, me dio falso a la hora de verificar');
//     // Store the attempted URL for redirecting
//     this.authService.redirectUrl = url;

//     // Navigate to the login page with extras
//     this.router.navigate(['/auth']);
//     return false;
//   }
  
  // canLoad(route: Route){
  //   let url: string = route.path
  //   console.log('Url' + url);
  //   if(this.authService.isLoggedIn){
  //     return true;
  //   }
  //   this.router.navigate(['/list-call']);
  //   return false;
  // }
}
