import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'other',
    title: 'Control Panel',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      // {
      //   id: 'sale',
      //   title: 'Home',
      //   type: 'item',
      //   icon: 'feather icon-home',
      //   url: '/dashboard/sale'
      // },
      // {
      //   id: 'data-table',
      //   title: 'Llamar a Clientes',
      //   type: 'item',
      //   icon: 'feather icon-user',
      //   url: '/tbl-datatable'
      // },
      {
        id: 'list-call',
        title: 'Call Requests',
        type: 'item',
        url: '/list-call',
        classes: 'nav-item',
        icon: 'feather icon-phone'
      },
      {
        id: 'full-calendar',
        title: 'Appointment Requests',
        type: 'item',
        url: '/full-calendar',
        classes: 'nav-item',
        icon: 'feather icon-calendar'
      },
      {
        id: 'contacts',
        title: 'Contacts',
        type: 'item',
        url: '/contact-list',
        icon: 'feather icon-list'
      },
      {
        id: 'mo',
        title: 'SMS MO',
        type: 'item',
        url: '/messages/mo',
        icon: 'feather icon-list'
      },
      {
        id: 'profiles',
        title: 'Profile',
        type: 'item',
        url: '/profiles/profile',
        icon: 'feather icon-user'
      },
      {
        id: 'broadcasteropt',
        title: 'Broadcast SMS',
        type: 'group',
        // url: '/Broadcast',
        icon: 'feather icon-radio',
        children: [
          {
            id: 'smschatopt',
            title: 'SMS Chat',
            type: 'collapse',
            icon: 'feather icon-mail',
            children: [
              {
                id: 'smschat',
                title: 'Live Chat',
                type: 'item',
                url: '/smschat'
              },
              {
                id: 'instantshipping',
                title: 'New Message',
                type: 'item',
                url: '/Broadcast/AddBroadcast/InstantShipping'
              },
              {
                id: 'reminder',
                title: 'Reminder',
                type: 'item',
                url: '/Broadcast/Reminder/'
              },
              {
                id: 'csvshipping',
                title: 'Bulk Message',
                type: 'item',
                url: '/Broadcast/CsvShipping/'
              },
              {
                id: 'broadcaster',
                title: 'History',
                type: 'item',
                url: '/History-campaing'
              }
            ]
          },
          {
            id: 'followup',
            title: 'Settings',
            type: 'item',
            url: '/Settings/Followup',
            classes: 'nav-item',
            icon: 'feather icon-settings'
          },
          // {
          //   id: 'otheropt',
          //   title: 'Settings',
          //   type: 'collapse',
          //   icon: 'feather icon-settings',
          //   children: [
          //     {
          //       id: 'followup',
          //       title: 'Followup',
          //       type: 'item',
          //       url: '/Settings/Followup'
          //     }
          //   ]
          // }
        ]
      }
      // {
      //   id: 'calendar-events',
      //   title: 'APPOINTMENT REQUESTS 2',
      //   type: 'item',
      //   url: '/calendar-events',
      //   classes: 'nav-item',
      //   icon: 'feather icon-calendar'
      // },
    ]
  },
  {
    id: 'channels',
    title: 'Channels',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      // {
      //   id: 'bot_sms',
      //   title: 'Bot SMS',
      //   type: 'item',
      //   url: '/profiles/sms',
      //   icon: 'feather icon-mail'
      // },
      // {
      //   id: 'bot_facebook',
      //   title: 'Bot Facebook',
      //   type: 'item',
      //   url: '/profiles/facebook',
      //   icon: 'feather icon-facebook'
      // },
      {
        id: 'bot_web',
        title: 'Bot WEB',
        type: 'item',
        url: '/profiles/webOff',
        icon: 'feather icon-globe'
      }
      // ,
      // {
      //   id: 'bot_webOff',
      //   title: 'Bot WEBOFF',
      //   type: 'item',
      //   url: '/profiles/webOff',
      //   icon: 'feather icon-globe'
      // }

    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
