import {Injectable, Output, EventEmitter} from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class ShareMessagesService{
    isOpen = false;

    @Output() change : EventEmitter<boolean> = new EventEmitter();

    toggleWebChat(){
        console.log("Ejecutado en el servicio >> toggleWebChat");
        this.isOpen = !this.isOpen;
        this.change.emit(this.isOpen);
    }
}