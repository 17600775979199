import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';

import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { switchMap} from 'rxjs/operators/switchMap';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/take';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {User} from '../models/user';
import {ShareVariableService} from './share-variable.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    apiHubURL = environment.apiHub.URL;

    constructor(private httpClient : HttpClient, private sharevariable : ShareVariableService ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  httpApiHubOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Basic ' + environment.apiHub.apiKey,
      'Content-Type': 'application/json'
    })
  }

  login(username: string, password: string) {
        return this.httpClient.post<any>(environment.apiHub.URL + 'user/login', { username, password }, this.httpApiHubOptions)
          .pipe(map(user => {
            debugger;
              // login successful if there's a jwt token in the response
              if (user && user.data[0].token) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(user.data[0]));
                  localStorage.setItem('currentBranchId', JSON.stringify(user.data[0].idoffice));
                  this.sharevariable.changeIdBranch(user.data[0].idoffice);
                  this.currentUserSubject.next(user);
              }
              return user;
          }));
  }

  //   login(email:string, password:string ) {
//     let body = new URLSearchParams();
//     body.set('user', email);
//     body.set('password', password);

//     return this.httpClient.post(environment.apiBBDDURL + 'login', body.toString(), this.httpOptions);
// }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentBranchId');
      this.currentUserSubject.next(null);
  }

  // user: Observable<firebase.User>
  // private userDetails: firebase.User = null;

  // public authState = new Subject();
  // isLoggedIn = false;
  // redirectUrl: string;

  // constructor(private firebaseAuth: AngularFireAuth, private httpClient : HttpClient) {
    // console.log("Constructador llamado auth service llamado");
    // this.user = this.firebaseAuth.authState.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       return of(true)
    //     } else {
    //       return of(null)
    //     }
    //   })
    // )
  // }

  // public async checkingPermissions(){
  //   console.log("Funcion para checar permisos llamada en authservice");
  //   await new Promise(resolve => {
  //   this.user = this.firebaseAuth.authState;
  //   this.user
  //   .take(1)
  //   .subscribe(
  //     (user) => {
  //       resolve();
  //       console.log('entre a verificar user en auth.service.ts');
  //       if (user) {
  //         this.isLoggedIn = true;
  //       } else {
  //         this.isLoggedIn = false;
  //       }
  //     }
  //   )}).catch(error => {
  //     console.log(error.message);
  //   });
  // }

  // signup(email: string, password: string) {
  //   return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  // }

  // login(email: string, password: string) {
  //   return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
  // }

  // signInWithGoogle() {
  //   return this.firebaseAuth.auth.signInWithPopup(
  //     new firebase.auth.GoogleAuthProvider()
  //   )
  // }

  // isLoggedIn_old() {
  //   if (this.userDetails == null) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // logout() {
  //   this.isLoggedIn = false;
  //   return this.firebaseAuth.auth.signOut();
  // }
}
