import {Injectable, Output, EventEmitter} from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class ShareVariableService{
    currentIdBranch = 0;
    currentUser : any;
    
    changeIdBranch(newId){
        this.currentIdBranch = newId;
    }
}